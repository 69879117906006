import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function Highlight({featuredImage, post}) {
  let isHomePage = false

  const pageQuery = useStaticQuery(graphql`
    query HighlightPost {
      allWpPost(
        filter: {categories: {nodes: {elemMatch: {id: {eq: "dGVybToxOTgy"}}}}}
        limit: 1
      ){
        nodes {
          title
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 520,
                    placeholder: BLURRED,
                    formats: [WEBP],
                    quality: 100
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  if (post === undefined) {
    isHomePage = true
    post = pageQuery.allWpPost.nodes[0]
    featuredImage = {
      image: getImage(post.featuredImage?.node.localFile),
      alt: post.featuredImage.node.altText,
    }
  }

  return (
    <section className="highlightSize pb-4 mt-16 xl:mt-20 mb-6 py-4 flex flex-col bg-greenQS">
      <Link to={post.uri} className="h-full w-full flex flex-col xl:flex-row items-center xl:justify-center xl:pl-14 gap-6 xl:gap-0 mx-auto max-w-xs md:max-w-screen-md xl:max-w-screen-xl">

        <GatsbyImage image={featuredImage.image} alt={featuredImage.alt} className="highlighImgSize xl:order-last rounded-full"/>

        <div className="xl:w-6/12 text-center xl:text-left">
          <span className={`${isHomePage? "" : "hidden" } px-8 py-1 bg-white rounded-full text-xs`}>Destaque</span>
          <h1 className="mt-4 text-sm md:text-3xl text-white font-semibold uppercase">{post.title}</h1>
        </div>

      </Link>
    </section>
  )
}

export default Highlight