import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PostsLayout from "../components/postsLayout"
import Highlight from "../components/underHeader/highlight"

function BlogIndex({ data, pageContext: { nextPagePath, previousPagePath } }) {

  const posts = data.allWpPost.nodes

  return (
    <Layout>

      <Seo title="Blog do QS" />

      <Highlight />

      <PostsLayout posts={posts} nextPagePath={nextPagePath} previousPagePath={previousPagePath} />

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "DD/MM/YYYY")
        title

        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400,
                  placeholder: BLURRED,
                  formats: [WEBP],
                  quality: 100
                )
              }
            }
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`